import React from 'react'
import SectionTitle from './components/SectionTitle'
import { StaticImage as Img } from 'gatsby-plugin-image'
import { Col, Container, Row } from 'react-bootstrap'
import Widget from './components/Widget'
import Content from './style'

import Team from './images'

export default function ContentSectionOne() {
  return (
    <Content backgroundColor="#f2f5fb">
      <Container>
        <Row className="align-items-center justify-content-center">
          {/* about-us Content */}
          <Col className="col-xxl-6 col-lg-7 col-md-9 col-xs-10 order-2 orde-lg-1">
            <Content.Box mbLG="60px" mb="30px">
              <SectionTitle
                subTitle=""
                title="What is Mintyfy?"
                text="Whether you’re at home or away, like to plan or just go with the flow,
                 finding out what to do around you anytime anywhere has never been so simple and fun!
                Explore cities like a local by discovering exciting experiences around you, using real-time
                 data so you never miss out again on what truly matters to you."
                subTitleProps={{ mb: "20px" }}
                titleProps={{ mb: "10px" }}
              />
            </Content.Box>
            <Content.Box>
              <Row className=" justify-content-center">
                {/* <Col className="col-lg-6 col-xs-6">
                  <Widget icon="far fa-envelope" title="Minimal Design" text="Generate Lorem Ipsum place holder<br class='d-none d-xl-block'> text for use in your Explore" />
                </Col>
                <Col className="col-lg-6 col-xs-6">
                  <Widget icon="fa fa-layer-group" title="Best Material" text="Generate Lorem Ipsum place holder<br class='d-none d-xl-block'> text for use in your Explore" />
                </Col> */}
              </Row>
            </Content.Box>
          </Col>
          {/*/ .about-us Content */}
          <Col xs="12" className="col-xxl-6 col-lg-5 col-md-8 col-xs-10 order-1 order-lg-2">
            <Content.ImageGroup>
              <Img src="../../../../assets/image/portfolio/minty/4.png" alt="content" layout="fullWidth" placeholder="blurred" />
              <Content.ImageShape>
                <Img src="../../../../assets/image/home-app/red-shape.png" alt="content" layout="fullWidth" placeholder="blurred" />
              </Content.ImageShape>
            </Content.ImageGroup>
          </Col>
        </Row>
        <Team />
      </Container>
    </Content>

  )
}